.nations {
   margin-left: 30px;
   
}


@media only screen and (max-width: 768px) {
   .nations {
     margin-right: 70px;
   }
 }